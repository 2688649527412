import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { SimplePageHero } from '../components/common';
import {
  LegalPageBody,
} from '../components/legal';
import { arraysEqual } from '../utils/helpers';


class LegalPageTemplate extends Component {
  state = {
    sectionsInView: [],
  }

  sectionInViewHandler = (sectionIndex, isInView) => {
    const { sectionsInView } = this.state;
    let newSectionsInView = [...sectionsInView];
    const indexExists = newSectionsInView.includes(sectionIndex);
    if (isInView) {
      // Intersection Observer has notified us section has come into view
      // indexExists prevents us from adding duplicates
      if (!indexExists) {
        newSectionsInView = [
          ...sectionsInView,
          sectionIndex,
        ];
      }
    } else {
      // Intersection Observer has notified us section is now out of view
      newSectionsInView = sectionsInView.filter(index => index !== sectionIndex);
    }
    if (!arraysEqual(sectionsInView, newSectionsInView)) {
      // Only if a new section has come into, or an existing section has come
      // out of view, we update the state.
      this.setState({
        sectionsInView: newSectionsInView,
      });
    }
  };

  render() {
    const {
      sectionsInView,
    } = this.state;
    // The 'active' section is the section closest to the top of the page that
    // are still in view (therefore, the smallest index in our array)
    const activeSection = sectionsInView.length > 0 ? sectionsInView.reduce((a, b) => Math.min(a, b)) : 0;
    const {
      data: {
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      pageTitle,
      heroSubtitle,
      sections,
      metaTitle,
      metaDescription,
      openGraphImage,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout
        location={location}
        seoData={seoData}
      >
        <SimplePageHero
          headline={pageTitle.text}
          description={heroSubtitle.text}
        />
        <LegalPageBody
          activeSection={activeSection}
          sectionInViewHandler={this.sectionInViewHandler}
          sections={sections}
        />
      </Layout>
    );
  }
}

export default LegalPageTemplate;

export const pageQuery = graphql`
  query LegalPageBySlug($uid: String!) {
    page: prismicLegal(uid: { eq: $uid }) {
      data {
        pageTitle: page_name {
          text
        }
        heroSubtitle: hero_subtitle {
          text
        }
        sections {
          content {
            html
          }
          sectionHeading: section_heading {
            text
          }
        }
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
      }
    }
  }
`;
