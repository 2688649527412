import React from 'react';
import {
  getNumberedAffix,
  getSectionId,
} from '../../utils/helpers';

const NavigationItem = ({
  index,
  isActive,
  scrollToHandler,
  sectionTitle,
}) => (
  <li className={`nav-item${isActive ? ' active' : ''}`}>
    <button
      type="button"
      className="anchor-link"
      onClick={event => scrollToHandler(event, getSectionId(index, sectionTitle))}
      aria-label={`Jump to ${sectionTitle} section`}
    >
      <span>
        {`${getNumberedAffix(index)} ${sectionTitle}`}
      </span>
    </button>
  </li>
);

export default NavigationItem;
