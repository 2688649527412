import React from 'react';
import { LegalPageNavigation, LegalPageSection } from '.';
import { Wrapper } from '../common';
import {
  getSectionId,
} from '../../utils/helpers';
import './LegalPageBody.scss';

const LegalPageBody = ({
  activeSection,
  sectionInViewHandler,
  sections,
}) => (
  <section className="legal-page-body">
    <Wrapper isBound>
      <div className="col col-left">
        <LegalPageNavigation
          activeSection={activeSection}
          sections={sections}
        />
      </div>
      <div className="col col-right">
        { sections && sections.map((section, index) => (
          <LegalPageSection
            key={getSectionId(index, section.sectionHeading.text)}
            index={index}
            section={section}
            sectionInViewHandler={sectionInViewHandler}
          />
        ))}
      </div>
    </Wrapper>
  </section>
);

export default LegalPageBody;
